$button-primary-background-color: $color-primary !default;
$button-primary-background-color-dark: $color-primary-dark !default;
$button-box-shadow: none !default;

%button {
	@extend %master-button;
	font-size: 1.6rem;
	min-height: 50px; // min-height au lieu de height car le texte du bouton peut etre sur plusieurs lignes
	width: 100%;
	padding: 0;
	appearance: none;
	border: none;
	text-decoration: none;
	outline: 0;
	box-sizing: border-box;
	cursor: pointer;
	text-align: center;

	@include gridle_state(md) {
		font-size: 1.4rem;
	}
}

%button--primary {
	@extend %button;
	background: $button-primary-background-color;
	color: white;
	box-shadow: $button-box-shadow;

	@include gridle_state(md) {
		&:hover,
		&:focus {
			background: $button-primary-background-color-dark;
		}
	}
}

%button--inverted {
	@extend %button;
	background: white;
	color: $button-primary-background-color;
	border: 2px solid $button-primary-background-color;
	@include gridle_state(md) {
		&:hover,
		&:focus {
			background: $button-primary-background-color-dark;
			color: white;
			border-color: $button-primary-background-color-dark;
		}
	}
}

%button--secondary {
	@extend %button;
	background: white;
	border: 1px solid $black;
	min-height: 40px;
	@extend %brand-button--secondary !optional;
}

%button--facebook {
	@extend %button;
	background: #4c69ba;
	color: white;
	box-shadow: none;
	font-size: 1.2rem;

	@include gridle_state(md) {
		font-size: 1.4rem;
	}
}

%button--loading {
	@extend %button;
	background: $grey-light;
	color: $grey-dark;
	cursor: wait;
}

%button--disabled {
	@extend %button;
	opacity: 0.2;
	pointer-events: none;
	cursor: not-allowed;
}
