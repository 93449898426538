$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-fr.svg") !default;
$marketing-slide-small-1: url("/static/icons/icon-marketing-ps-1.svg") !default;
$marketing-slide-small-2: url("/static/icons/icon-marketing-ps-2.svg") !default;
$marketing-slide-small-3: url("/static/icons/icon-marketing-ps-3.svg") !default;

$icon-play-white: url("/static/icons/icon-play-white.svg") !default;
$icon-play-dark: url("/static/icons/icon-play-dark.svg") !default;

.icon {
	display: inline-block;
	height: 35px;
	width: 35px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	&--arrow-up-grey {
		background-image: url("/static/icons/icon-arrow-up-grey.svg");
	}

	&--arrow-down-grey {
		background-image: url("/static/icons/icon-arrow-down-grey.svg");
	}

	&--badge-emirates {
		background-image: url("/static/icons/icon-badge-emirates.svg");
	}

	&--email-white {
		background-image: url("/static/icons/icon-email-white.svg");
	}

	&--play-dark {
		background-image: $icon-play-dark;
	}

	&--play-white {
		background-image: $icon-play-white;
	}

	&--landing-accroche {
		background-image: url("/static/icons/icon-landing-accroche.svg");
	}

	&--landing-marketing-message-1 {
		background-image: url("/static/icons/icon-landing-marketing-message-1.svg");
	}

	&--landing-marketing-message-2 {
		background-image: url("/static/icons/icon-landing-marketing-message-2.svg");
	}

	&--circle-cross-grey {
		background-image: url("/static/icons/icon-circle-cross-grey.svg");
	}

	&--circle-cross-white {
		background-image: url("/static/icons/icon-circle-cross-white.svg");
	}

	&--error {
		background-image: url("/static/icons/icon-error.svg");
	}

	&--info-grey {
		background-image: url("/static/icons/icon-info-grey.svg");
	}

	&--info {
		background-image: url("/static/icons/icon-info.svg");
	}

	&--info-white {
		background-image: url("/static/icons/icon-info-white.svg");
	}

	&--info-blue {
		background-image: url("/static/icons/icon-info-blue.svg");
	}

	&--info-red-ryanair {
		background-image: url("/static/icons/icon-info-red-ryanair.svg");
	}

	&--info-red {
		background-image: url("/static/icons/icon-info-red.svg");
	}

	&--facebook {
		background-image: url("/static/icons/icon-facebook.svg");
	}

	&--facebook-white {
		background-image: url("/static/icons/icon-facebook-white.svg");
	}

	&--twitter {
		background-image: url("/static/icons/icon-twitter.svg");
	}

	&--next {
		background-image: url("/static/icons/icon-next-1.svg");
	}

	&--previous {
		background-image: url("/static/icons/icon-previous-1.svg");
	}

	&--cross {
		background-image: url("/static/icons/icon-cross.svg");
	}

	&--cross-dark {
		background-image: url("/static/icons/icon-cross-dark-1.svg");
	}

	&--cross-white {
		background-image: url("/static/icons/icon-cross-white.svg");
	}

	&--down-grey-dark {
		background-image: url("/static/icons/icon-down-grey-dark.svg");
	}

	&--left-white {
		background-image: url("/static/icons/icon-left-white.svg");
	}

	&--right-grey-light {
		background-image: url("/static/icons/icon-right-grey-light.svg");
	}

	&--trident-3 {
		background-image: url("/static/icons/icon-trident-3.svg");
	}

	&--trident-4 {
		background-image: url("/static/icons/icon-trident-4.svg");
	}

	&--trident-5 {
		background-image: url("/static/icons/icon-trident-5.svg");
	}

	&--itinerary-arrow {
		background-image: url("/static/icons/icon-itinerary-arrow.svg");
	}

	&--entonnoir {
		background-image: url("/static/icons/icon-entonnoir.svg");
	}

	&--loupe-white {
		background-image: url("/static/icons/icon-loupe-white.svg");
	}

	&--plus-white {
		background-image: url("/static/icons/icon-plus-white.svg");
	}

	&--moins-white {
		background-image: url("/static/icons/icon-moins-white.svg");
	}

	&--timer {
		background-image: url("/static/icons/icon-timer.svg");
	}

	&--bell {
		background-image: url("/static/icons/icon-bell-darkgrey.svg");
	}

	&--more-below-white {
		background-image: url("/static/icons/icon-more-below-white-2.svg");
	}

	&--lock-dark {
		background-image: url("/static/icons/icon-lock-darkgrey.svg");
	}

	&--inedit {
		background-image: url("/static/icons/icon-surmesure.svg");
	}

	&--inedit-dark {
		background-image: url("/static/icons/icon-surmesure-dark.svg");
	}

	&--economy {
		background-image: url("/static/icons/icon-economie.svg");
	}

	&--economy-dark {
		background-image: url("/static/icons/icon-economie-dark.svg");
	}

	&--exception {
		background-image: url("/static/icons/icon-exception.svg");
	}

	&--exception-dark {
		background-image: url("/static/icons/icon-exception-dark.svg");
	}

	&--tick {
		background-image: url("/static/icons/icon-tick.svg");
	}

	&--visa-secure {
		background-image: url("/static/icons/icon-visa-secure.svg");
	}

	&--visa-colorful {
		background-image: url("/static/icons/icon-visa-color.svg");
	}

	&--casino4x-colorful {
		background-image: url("/static/icons/icon-casino4x-colorful-1.png");
	}

	&--maestro-colorful {
		background-image: url("/static/icons/icon-maestro-colorful.svg");
	}

	&--visaelectron-colorful {
		background-image: url("/static/icons/icon-visaelectron-colorful-2.svg");
	}

	&--cb-colorful {
		background-image: url("/static/icons/icon-cb-colorful.svg");
	}

	&--amex-secure {
		background-image: url("/static/icons/icon-amex-secure.svg");
	}

	&--amex-official {
		background-image: url("/static/icons/icon-amex-official.svg");
	}

	&--amex-colorful {
		background-image: url("/static/icons/icon-amex-colorful.svg");
	}

	&--mastercard-secure {
		background-image: url("/static/icons/icon-mastercard-secure.svg");
	}

	&--mc-colorful {
		background-image: url("/static/icons/icon-mastercard-color-2.svg");
	}

	&--home-slide-1 {
		background-image: url("/static/icons/icon-home-slide-1.svg");
	}

	&--home-slide-2 {
		background-image: url("/static/icons/icon-home-slide-2.svg");
	}

	&--home-slide-3 {
		background-image: url("/static/icons/icon-home-slide-3.svg");
	}

	&--marketing-slide-small-message {
		background-image: url("/static/icons/icon-marketing-slide-small-message.svg");
	}

	&--marketing-slide-small-message-af {
		background-image: url("/static/icons/icon-marketing-slide-small-message-af.svg");
	}

	&--marketing-slide-small-message-af-GB {
		background-image: url("/static/icons/icon-marketing-slide-small-message-af-GB.svg");
	}

	&--marketing-slide-small-logo {
		background-image: $marketing-slide-small-logo;
	}

	&--marketing-slide-message {
		background-image: $marketing-slide-small-1;
	}

	&--marketing-slide-small-1 {
		background-image: $marketing-slide-small-1;
	}

	&--marketing-slide-small-ek-birthday {
		background-image: url("/static/icons/icon-marketing-slide-small-ek-birthday.svg");
	}

	&--marketing-slide-small-2 {
		background-image: $marketing-slide-small-2;
	}

	&--marketing-slide-small-3 {
		background-image: $marketing-slide-small-3;
	}

	&--marketing-slide-small-identify {
		background-image: url("/static/icons/icon-marketing-slide-small-identify.svg");
	}

	&--logo-perfectstay {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PS-fr-FR.svg");
	}

	&--logo-perfectstay-white {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PS-fr-FR-white.svg");
	}

	&--logo-with-perfectstay-col {
		background-image: url("/static/icons/icon-logo-with-perfectstay-col.svg");
	}

	&--logo-offers-sold-with-pfs-col {
		background-image: url("/static/icons/icon-logo-offers-sold-with-pfs-col.svg");
	}

	&--logo-with-perfectstay {
		background-image: url("/static/icons/icon-with-perfectstay.svg");
	}

	&--logo-with-perfectstay-white {
		background-image: url("/static/icons/icon-with-perfectstay-white.svg");
	}

	&--success-dark {
		background-image: url("/static/icons/icon-success-dark.svg");
	}

	&--flash-red {
		background-image: url("/static/icons/icon-flash-sale-red.svg");
	}

	&--hotel-offert-desktop {
		background-image: url("/static/icons/icon-hotel-offert-desktop.svg");
	}

	&--hotel-offert-mobile {
		background-image: url("/static/icons/icon-hotel-offert-mobile.svg");
	}

	&--paiement-4x-dark {
		background-image: url("/static/icons/icon-paiement-4x-dark.svg");
	}

	&--paiement-4x-white {
		background-image: url("/static/icons/icon-paiement-4x-white.svg");
	}

	&--cvc-cb {
		background-image: url("/static/icons/icon-cvc-cb.svg");
	}

	&--cvc-amex {
		background-image: url("/static/icons/icon-cvc-amex.svg");
	}

	&--mc-small {
		background-image: url("/static/icons/icon-mastercard-color-2.svg");
	}

	&--visa-small {
		background-image: url("/static/icons/icon-visa-color.svg");
	}

	&--amex-small {
		background-image: url("/static/icons/icon-amex-colorful.svg");
	}

	&--cb-small {
		background-image: url("/static/icons/icon-cb-small.svg");
	}

	&--maestro-small {
		background-image: url("/static/icons/icon-maestro-colorful.svg");
	}

	&--giropay {
		background-image: url("/static/icons/icon-giropay.svg");
	}

	&--sofort {
		background-image: url("/static/icons/icon-klarna.svg");
	}

	&--flag-fr-FR {
		background-image: url("/static/icons/icon-flag-FR.svg");
	}

	&--flag-fr-BE {
		background-image: url("/static/icons/icon-flag-BE.svg");
	}

	&--flag-nl-BE {
		background-image: url("/static/icons/icon-flag-BE.svg");
	}

	&--flag-nl-NL {
		background-image: url("/static/icons/icon-flag-NL.svg");
	}

	&--flag-en-GB {
		background-image: url("/static/icons/icon-flag-EN.svg");
	}

	&--flag-fr-CH {
		background-image: url("/static/icons/icon-flag-CH.svg");
	}

	&--flag-de-CH {
		background-image: url("/static/icons/icon-flag-CH.svg");
	}

	&--flag-de-DE {
		background-image: url("/static/icons/icon-flag-DE.svg");
	}

	&--flag-es-ES {
		background-image: url("/static/icons/icon-flag-ES.svg");
	}

	&--flag-it-IT {
		background-image: url("/static/icons/icon-flag-IT.svg");
	}

	&--flag-de-AT {
		background-image: url("/static/icons/icon-flag-AT.svg");
	}

	&--travel-or {
		background-image: url("/static/icons/icon-travel-or.svg");
	}

	&--collection-intemporelle {
		background-image: url("/static/icons/icon-collection-intemporelle.svg");
	}

	&--takeoff-white {
		background-image: url("/static/icons/icon-takeoff-white.svg");
	}

	&--logo-cdiscount-voyages {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-cdiscount.png");
	}

	&--logo-emirates {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-emirates-1.svg");
	}

	&--logo-corsair {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-corsair.svg");
	}

	&--logo-cdiscount {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-CD-fr-FR-white.svg");
	}

	&--logo-privee {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-vente-privee.svg");
	}

	&--logo-vente-privee-le-voyage {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-vente-privee-le-voyage.svg");
	}

	&--logo-beachcomber {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-beachcomber.svg");
	}

	&--logo-airfrance {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-airfrance.svg");
	}

	&--logo-holidays-pirates {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-holidays-pirates.svg");
	}

	&--logo-verychic {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-verychic.png");
	}

	&--logo-vente-exclusive {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-vente-exclusive.png");
	}

	&--logo-deindeal {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-deindeal.png");
	}

	&--logo-mystore {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-mystore.png");
	}

	&--sourcing {
		background-image: url("/static/icons/icon-sourcing.svg");
	}

	&--technology {
		background-image: url("/static/icons/icon-technology.svg");
	}

	&--expertise {
		background-image: url("/static/icons/icon-expertise.svg");
	}

	&--logo-la-collection-air-france {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-af-2.svg");
	}

	&--logo-les-escapades-corsair {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-ss-2.svg");
	}

	&--logo-emirate-the-list {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-ek.svg");
	}

	&--marketing-slide-small-corsair {
		background-image: url("/static/icons/icon-marketing-slide-small-corsair.svg");
	}

	&--marketing-slide-small-amex {
		background-image: url("/static/icons/icon-marketing-slide-small-amex.svg");
	}

	&--amex-gold {
		background-image: url("/static/icons/icon-amex-gold.png");
	}

	&--flash-sale {
		background-image: url("/static/icons/icon-flash-sale.svg");
	}

	&--search {
		background-image: url("/static/icons/icon-search.svg");
	}

	&--calendar-number {
		background-image: url("/static/icons/icon-calendar-number.svg");
	}

	&--calendar-number-grey {
		background-image: url("/static/icons/icon-calendar-number-grey.svg");
	}

	&--flag {
		background-image: url("/static/icons/icon-flag.svg");
	}

	&--funnel {
		background-image: url("/static/icons/icon-funnel.svg");
	}

	&--funnel-grey {
		background-image: url("/static/icons/icon-funnel-grey.svg");
	}

	&--opening-quotes {
		background-image: url("/static/icons/icon-opening-quotes.svg");
	}

	&--closing-quotes {
		background-image: url("/static/icons/icon-closing-quotes.svg");
	}

	&--flying-blue {
		background-image: url("/static/icons/icon-flying-blue.svg");
	}

	&--magnifying-glass {
		background-image: url("/static/icons/icon-magnifying-glass.svg");
	}

	&--magnifying-glass-grey {
		background-image: url("/static/icons/icon-magnifying-glass-grey.svg");
	}

	&--abn-amro {
		background-image: url("/static/icons/icon-abn-amro.png");
	}

	&--asn-bank {
		background-image: url("/static/icons/icon-asn-bank.png");
	}

	&--bunq {
		background-image: url("/static/icons/icon-bunq.png");
	}

	&--ing {
		background-image: url("/static/icons/icon-ing.png");
	}

	&--knab {
		background-image: url("/static/icons/icon-knab.png");
	}

	&--moneyou {
		background-image: url("/static/icons/icon-moneyou.png");
	}

	&--rabobank {
		background-image: url("/static/icons/icon-rabobank.png");
	}

	&--regiobank {
		background-image: url("/static/icons/icon-regiobank.png");
	}

	&--sns-bank {
		background-image: url("/static/icons/icon-sns-bank.png");
	}

	&--triodos-bank {
		background-image: url("/static/icons/icon-triodos-bank.png");
	}

	&--van-lanschot-bankiers {
		background-image: url("/static/icons/icon-van-lanschot-bankiers.png");
	}

	&--abn-amro {
		background-image: url("/static/icons/icon-abn-amro.png");
	}

	&--bancontact {
		background-image: url("/static/icons/icon-bancontact.svg");
	}

	&--postfinance-card {
		background-image: url("/static/icons/icon-postfinance-card.svg");
	}

	&--postfinance-efinance {
		background-image: url("/static/icons/icon-postfinance-efinance.svg");
	}

	&--swissbilling {
		background-image: url("/static/icons/icon-swissbilling.png");
	}

	&--ideal {
		background-image: url("/static/icons/icon-ideal.png");
	}

	&--paypal {
		background-image: url("/static/icons/icon-paypal.svg");
	}

	&--email-grey {
		background-image: url("/static/icons/icon-email-grey.svg");
	}

	&--mail {
		background-image: url("/static/icons/icon-mail.svg");
	}

	&--bagages {
		background-image: url("/static/icons/icon-bagages.svg");
	}

	&--bagage-soute {
		background-image: url("/static/icons/icon-bagage-soute.svg");
	}

	&--bagage-main {
		background-image: url("/static/icons/icon-bagage-a-main-1.svg");
	}

	&--bagage-cabine {
		background-image: url("/static/icons/icon-bagage-cabine.svg");
	}

	&--cdiscount {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-CD-fr-FR-white.svg");

		&-camping {
			background-image: url("/static/icons/icon-cdiscount-camping.png");
		}

		&-croisieres {
			background-image: url("/static/icons/icon-cdiscount-croisieres.png");
		}

		&-home {
			background-image: url("/static/icons/icon-cdiscount-home.png");
		}

		&-hotel {
			background-image: url("/static/icons/icon-cdiscount-hotel.png");
		}

		&-location {
			background-image: url("/static/icons/icon-cdiscount-location.png");
		}

		&-mesure {
			background-image: url("/static/icons/icon-cdiscount-mesure.png");
		}

		&-sejours {
			background-image: url("/static/icons/icon-cdiscount-sejours.png");
		}

		&-ski {
			background-image: url("/static/icons/icon-cdiscount-ski.png");
		}

		&-vols {
			background-image: url("/static/icons/icon-cdiscount-vols.png");
		}

		&-train {
			background-image: url("/static/icons/icon-cdiscount-train.png");
		}

		&--plane {
			background-image: url("/static/icons/icon-plane.svg");
		}
	}

	&--dialog {
		background-image: url("/static/icons/icon-dialog.svg");
	}

	&--insurance-free-cancellation {
		background-image: url("/static/icons/icon-insurance-free-cancellation.svg");

		&--white {
			background-image: url("/static/icons/icon-insurance-free-cancellation-white.svg");
		}
	}

	&--clock {
		background-image: url("/static/icons/icon-clock.svg");
	}

	&--check-shield {
		background-image: url("/static/icons/icon-check-shield.svg");
	}

	&--check-shield-white {
		background-image: url("/static/icons/icon-check-shield-white.svg");
	}

	&--calendar-2021 {
		background-image: url("/static/icons/icon-calendar-2021.svg");
	}

	&--external-link {
		background-image: url("/static/icons/icon-external-link.svg");
	}

	&--chevron {
		background-image: url("/static/icons/icon-picto-chevron.svg");
	}

	&--round-blue-next {
		background-image: url("/static/icons/icon-round-blue-next.svg");
	}

	&--round-blue-previous {
		background-image: url("/static/icons/icon-round-blue-previous.svg");
	}

	&--warning-white {
		background-image: url("/static/icons/icon--picto-warning.svg");
	}

	&--suivi-white {
		background-image: url("/static/icons/icon--picto-suivi.svg");
	}

	&--avis-verifies-etoiles-noires {
		background-image: url("/static/icons/avis-verifies-etoiles-noires.svg");
	}

	&--avis-verifies-etoiles-jaunes {
		background-image: url("/static/icons/avis-verifies-etoiles-pleines.svg");
	}

	&--flex {
		background-image: url("/static/icons/icon-flex.svg");
	}

	&--flight-hotel {
		background-image: url("/static/icons/icon-flight-hotel.svg");
	}

	&--lock {
		background-image: url("/static/icons/icon-lock.svg");
	}

	&--tripadvisor {
		background-image: url("/static/icons/icon-tripadvisor.svg");
	}

	&--holidaycheck {
		background-image: url("/static/icons/icon-holidaycheck.svg");
	}

	&--hand-holding-airplane {
		background-image: url("/static/icons/front-view-hand-holding-airplane-figurine-with-copy-space.png");
	}

	&--travellers {
		background-image: url("/static/icons/icon-travellers.svg");
	}

	&--hotel {
		background-image: url("/static/icons/icon-hotel.svg");
	}

	&--hotel-white {
		background-image: url("/static/icons/icon-hotel-white.svg");
	}

	&--plane-hotel-white {
		background-image: url("/static/icons/icon-plane-hotel-white.svg");
	}

	&--chat {
		background-image: url("/static/icons/icon-chat.svg");
	}
}
