@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$footer-text-color: white !default;

.reassurance {
	color: $footer-text-color;

	// permet d'écraser la règle globale appliqué par le header custom cdiscount
	// ( /app/pages/.brand/CD/Header/Header.jsx ligne 2752)
	&::after,
	&::before {
		display: none;
	}

	&__item {
		text-align: center;
		color: $footer-text-color;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__label {
		@extend %h2;

		font-size: 1.4rem;
		letter-spacing: 0;
		margin-top: 5px;
		color: $footer-text-color;
	}

	&__payment {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;

		svg {
			margin-right: 5px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	@include gridle_state(md) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		&__item {
			margin-bottom: 0;
		}
	}
}
