@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.aside-menu {
	display: flex;
	flex-direction: column;
	min-width: 270px;
	height: 100vh;
	overflow-y: auto;
	width: 100vw;
	background: white;

	@include gridle_state(md) {
		width: 25vw;
	}

	&__header {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		padding: 15px;
		background: white;

		.aside-menu__logo {
			.logo-brand {
				height: 45px;
				width: 200px;
			}
		}
	}

	&__close {
		position: absolute;

		left: 20px;
		top: 20px;

		.icon {
			height: 15px;
			width: 15px;
		}
	}

	&__nav {
		flex: 1 1 0%;
		color: $color-secondary;
		background: white;

		.rc-collapse > .rc-collapse-item > .rc-collapse-header {
			outline: none; // pas de bordure bleu du navigateur
		}

		.nav {
			&__item {
				&-link {
					@extend %font-bold;
					font-size: 2rem;
					margin: 0 0 0 30px;
					text-transform: uppercase;
				}
			}

			&__subitem {
				&-link {
					margin: 0 0 0 40px;
				}
			}
		}

		.relative-link {
			justify-content: flex-start;
		}
	}

	.separator {
		margin: 15px 0;
		background-color: $grey-medium;
		width: 100vw;
	}
}
