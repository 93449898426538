@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$input-border-color: $active-color !default;
$button-primary-background-color: $active-color !default;

.auth {
	$this: &;

	position: relative;
	width: 100%;

	@include gridle_state(md) {
		margin: 0;
	}

	.control-group__input {
		@include gridle_state(md) {
			&:focus,
			&:hover {
				border: 2px solid $active-color;
			}
		}
	}

	.auth__button {
		padding: 10px;
	}

	&__success-message {
		flex: 1 1 0%;
	}

	&__loader,
	&__main {
		background: white;
		padding: 15px;
		border-radius: $border-radius-big;

		@include gridle_state(md) {
			padding: 20px 25px;
		}
	}

	&__action {
		position: relative;
		margin-top: 25px;

		.popover {
			bottom: 40px;
			margin-right: 0;
			margin-left: 0;
		}
	}

	&__facebook {
		font-size: 1.2rem;
		position: relative;
		white-space: nowrap;

		.button--facebook {
			letter-spacing: 0;
			padding: 0 10px;

			@include gridle_state(md) {
				padding: 0 5px;
			}
		}

		.icon {
			height: 20px;
			width: 20px;
			margin-right: 5px;
		}
	}

	&__headline {
		@extend %h4;

		width: 100%;
		line-height: 1.2;
		margin: 5px 0 18px;

		&--small {
			@extend %font-regular;

			font-size: 1.2rem;
			margin-top: 10px;
		}
	}

	&__separator {
		font-size: 1.2rem;
		display: flex;
		margin: 15px 0;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;

		@include gridle_state(md) {
			margin: 20px 0;
		}

		&::before {
			content: "";
			height: 1px;
			background: $black;
			flex: 1 1 0%;
			margin: 0 14px 0 0;
		}

		&::after {
			content: "";
			height: 1px;
			background: $black;
			flex: 1 1 0%;
			margin: 0 0 0 14px;
		}
	}

	&__form {
		.control-group {
			margin-bottom: 10px;
		}
	}

	&__accept-cgv {
		box-sizing: border-box;
		height: 40px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		@include gridle_state(md) {
			color: inherit;
		}

		.checkbox__icon {
			svg {
				cursor: pointer;
			}
		}

		.checkbox {
			width: 100%; // fix ie 11 deborde du cadre
		}
	}

	&__terms {
		font-size: 1rem;
		user-select: none;

		.relative-link {
			font-size: 1rem;
			color: $black;
			display: inline;
			text-decoration: underline;
		}
	}

	&__button {
		&--secondary {
			@extend %link;

			text-decoration: underline;
		}
	}

	&__switch {
		padding: 0 0 20px;
		display: flex;
		justify-content: space-between;

		&__item {
			@extend %text-bold;

			opacity: 0.5;
			cursor: pointer;
			text-transform: lowercase;
			height: 25px;

			&::first-letter {
				text-transform: capitalize;
			}

			&--active {
				opacity: 1;
				border-bottom: 3px solid $button-primary-background-color;
			}
		}
	}

	&__forgotten-password {
		box-sizing: border-box;
		display: flex;
		height: 40px;
		align-items: center;
		margin-bottom: 10px;

		@include gridle_state(md) {
			.relative-link {
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__intro {
		margin-bottom: 20px;
		text-align: justify;
		display: flex;
		align-items: center;

		&__close {
			margin-left: 15px;

			.icon {
				width: 20px;
				height: 20px;
			}
		}
	}

	&__resetpassword {
		.alert-message--error {
			margin-bottom: 20px;
		}

		.alert-message--success {
			margin-top: 20px;
		}
	}

	&__resetpassword__action {
		.button {
			margin-top: 10px;
		}

		.alert-message--error .alert-message__text {
			display: block;
		}
	}

	&__changepassword__action {
		.popover {
			bottom: 120px;
		}

		.button {
			margin: 10px 0;
		}
	}

	&__footer {
		margin-top: 15px;
	}

	&__countdown {
		display: inline;

		&-item:not(:first-child) {
			margin-left: 5px;
		}
	}

	&__password {
		position: relative;

		.control-group {
			&__input {
				padding-right: 40px;
			}

			&__note {
				position: absolute;
				right: 5px;
				margin: 0;
				top: 50%;
				width: 24px;
				height: 24px;
				transform: translateY(-50%);
				z-index: 1;
				cursor: pointer;

				svg {
					fill: $black;
				}
			}
		}

		&.control-group--disabled {
			opacity: 0.3;
		}
	}

	&__loader {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
	}

	&--verifying {
		#{$this}__main {
			visibility: hidden;
		}
	}

	.form-error-messages {
		margin-bottom: 10px;
	}
}
